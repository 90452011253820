.recorder__script-text {
    white-space: pre-wrap;
}

.video-editor > section {
    background-color: rgba(56, 213, 32, 0.25);
}

.script-assistant-logo {
    background-image: url("/dc100-icon.png");
    background-size: cover;
}

.script-assistant-container {
    background-image: url("/chatgpt-logo.png");
    background-position: center 40%;
    background-repeat: no-repeat;
    background-size: 20%;
}
